<template>
  <div class="dci">
    <div class="dci-content">
      <el-image class="image" :src="require('@/assets/image/navlogo.jpg')"></el-image>
      <div class="text">
        DCI（‌数字版权唯一标识符）‌的申请过程涉及多个步骤，‌包括通过技术服务方提供的API接口完成DCI标准化升级，‌用户原创内容创作完成后提交DCI申领，‌数字版权链（‌DCI体系3.0）‌对其作品版权信息的真实性、‌有效性、‌一致性进行识别、‌记录和分析，‌通过智能算法核验后可获得DCI。‌
      </div>
      <div class="text">
        DCI作为数字版权唯一标识符，‌描述了用户与数字内容一一映射的权属关系，‌支撑数字内容价值的进一步释放，‌促进数字内容授权与价值转化。‌获得DCI的用户，‌可进一步按需自愿办理数字版权登记业务，‌获得《‌作品登记证书（‌数字版）‌》‌。‌
      </div>
      <div class="text">
        此外，‌中国版权保护中心与多家互联网头部平台企业和垂直领域代表性应用共同参与了《‌基于数字版权链（‌DCI体系3.0）‌的互联网版权服务基础设施建设与试点应用》‌项目，‌该项目获得了中宣部、‌中央网信办等十六部门联合批准，‌成为国家“区块链+版权”特色应用试点之一。‌这一合作旨在探索构建互联网版权服务创新机制和产业新生态，‌助力国家文化数字化战略实施和产业高质量发展
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Dci",
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    to (path) {
    }
  }
}
</script>

<style lang="less" scoped>
.dci {
  box-sizing: border-box;
  padding: 1.5vw 6vw;
  margin: 1.5vw 0 4vw;
  background-color: #fff;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      width: 100px;
      height: 100px;
      margin-bottom: 40px;
    }

    .text {
      line-height: 26px;
      font-size: 14px;
    }
  }
}
</style>